@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

* {
    padding: 0;
    margin: 0;
    font-family: 'Manrope';
    font-weight: 800;
    font-size: 20px;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    background-color: white;
}
/* Video Tag  */
/* .videoTag {
    height: 100%;
    width: 100%;
    float: left;
} */

/* Beranda */

.container-beranda {
    width: 100%;
    min-width: 200px;
    margin: auto;
}
.hero-beranda {
    width: 90%;
    padding-top: 5rem;
    padding-bottom: 10rem;
    margin: auto;
}
.hero-beranda h1 {
    font-size: 70px;
}

/* Feature Work  */
.bg-feature-beranda {
    padding-top: 50px;
    background-color: rgba(255, 248, 242, 0.445);
    padding-bottom: 50px;
}
.feature-beranda {
    justify-content: center;
    width: 90%;
    margin: auto;
    background-color: transparent;
}
.feature-beranda h2 {
    font-size: 60px;
    font-weight: 800;
    background-color: transparent;
}
.work-space {
    height: 100%;
    padding-top: 1rem;
    display: flex grid;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}
.work-space img {
    max-width: 100%;
    height: 70px;
    padding: 10px;
    flex-wrap: wrap;
    padding-right: 2rem;
    justify-content: center;
    background-color: transparent;
}


button {
    cursor: pointer;
    border-radius: 24px;
    padding: 10px;
    box-shadow: 5px 10px;
    border-radius: 4px;
    padding: 15px 25px;
    animation-delay: 5ms;
    /* margin: 10px 10px; */
}
button:hover {
    color: white;
    transition-duration: .2ms;
    background-color: rgb(0, 0, 0);
}

/* Jurnal */
.container-jurnal {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: rgb(255, 255, 255);
    justify-content: center;
}
.box-jurnal {
    margin: auto;
    width: 90%;
    box-sizing: border-box;
    flex-wrap: wrap;
}
.content-jurnal {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
}
.article-jurnal {
    border: 1px solid;
    padding: 20px;
    margin: 20px;
    box-shadow: 5px 10px;
    border-radius: 12px;
}

.article-jurnal h1 {
    font-size: 0px;
}
.article-jurnal p {
    font-size: 20px;
    /* font-style: italic; */
    font-weight: 400;
}
/* .box-jurnal {
    display: flex ;
    margin: auto;
    width: 90%;
    box-sizing: border-box;
    flex-wrap: wrap;
} */




/* Contact  */
.bg-feature-contact {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: rgb(255, 255, 255);
}
.hero-contact {
    margin: auto;
    width: 90%;
}
.bg-feature-contact h1 {
    text-align: center;
    font-size: 56px;
    font-weight: 800;
    background-color: transparent;
}

.bg-feature-contact p {
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    background-color: transparent;
}

.feature-contact {
    margin: auto;
    width: 80%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: white;
    border: 1px solid;
    padding: 10px;
    box-shadow: 5px 10px;
    border-radius: 12px;
}
.box-contact {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
    width: 90%;
    background-color: transparent;
}
.lets-talk {
    color: rgb(190, 42, 42);
    background-color: transparent;
}
.lets-talk h1 {
    font-size: 24px;
    font-weight: 800;
    color: rgb(190, 42, 42);
    background-color: transparent;
}
.lets-talk p {
    font-size: 17px;
    background-color: transparent;
}
.view-talk iframe {
    border-radius: 8px;    
    width: 100%;
}