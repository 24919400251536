.sticky {
    position: sticky;
    top: 0;
    z-index: 100;
    box-shadow: 0 20px 20px -28px rgba(0,0,0,.1);
    transition: 0.3ms;
    align-items: center;
}

.container-nav {
    width: 90%;
    min-width: 200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    padding-top: 1rem;
    align-items: center;
}

.logo {
    font-size: 40px;
    font-weight: 800;
    color: rgb(44,69,82);
}

.navbar {
    display: flex;
    padding: 10px;
}

.navbar li {
    padding: 8px 18px;
    font-size: 17px;
    font-weight: 600;
    color: rgb(44,69,82);
    border-radius: 4px;
    border: none;
}
.navbar li:hover  {
    background-color: rgba(255, 228, 196, 0.185);
}