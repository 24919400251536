.container-footer {
    background-color: rgb(42,43,47);
    padding-top: 50px;
}
.bg-container {
    width: 90%;
    margin: auto;
    background-color: transparent;
}
.logo-footer {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
}

.sidebar-footer {
    display: flex;
    background-color: transparent;
}
/* Mengatur Logo footer */
.logo-sidebar {
    color: white;
    background-color: transparent;
}
.logo-sidebar h1 {
    color: white;
    font-size: 36px;
    font-weight: 800;
    background-color: transparent;
}
.logo-sidebar p {
    color: white;
    font-size: 28px;
    font-weight: 400;
    background-color: transparent;
}
/* Mengatur List Menu  */

.onefooter {
    padding-right: 6rem;
    background-color: transparent;
}
.onefooter li {
    background-color: transparent;
    font-size: 15px;
    font-weight: 600;
    color: white;
}
.onefooter li:hover {
    color: rgb(32, 124, 89);
    text-shadow: 10px;
    /* text-decoration: line-through; */
}

.twofooter {
    padding-right: 6rem;
    background-color: transparent;
}
.twofooter li {
    background-color: transparent;
    font-size: 15px;
    font-weight: 600;
    color: white;
}
.twofooter li:hover {
    color: rgb(32, 124, 89);
    text-shadow: 10px;
    /* text-decoration: line-through; */
}

.threefooter {
    background-color: transparent;
}
.threefooter li {
    background-color: transparent;
    font-size: 15px;
    font-weight: 600;
    color: white;
    padding-right: 20px;
}
.threefooter li:hover {
    color: rgb(32, 124, 89);
    text-shadow: 10px;
    /* text-decoration: line-through; */
}

/* Buttom footer */
.buttom-footer {
    width: 90%;
    margin: auto;
    padding-top: 5rem;
    padding-bottom: 40px;
    background-color: rgb(42,43,47);
    align-items: center;
}
.buttom-footer-sidebar {
    height: 100%;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    align-items: stretch;
}
.buttom-footer-sidebar h1 {
    display: flex;
    background-color: transparent;
    font-size: 15px;
    font-weight: 600;
    color: white;
}
.buttom-footer-sidebar svg {
    background-color: transparent;
    font-size: 17px;
    font-weight: 800;
}
.buttom-footer-sidebar p {
    
    background-color: transparent;
    color: white;
}
.buttom-footer-sidebar a {
    display: flex;
    background-color: transparent;
    font-size: 15px;
    font-weight: 600;
    /* text-decoration: underline; */
    color: white;
    padding-left: .2rem;
}
.buttom-footer a:hover {
    color: rgb(32, 124, 89);
}

/* subIcon  */
.subIcon {
    display: flex;
    background-color: rgb(42,43,47);
}
.subIcon a {
    padding-right: 10px;
}
.subIcon svg {
    background-color: transparent;
    font-size: 17px;
    font-weight: 800;
}
.subIcon a:hover {
    color: rgb(32, 124, 89);
}
/* subPrivacy */
.subPrivacy {
    display: flex;
    background-color: transparent;
}
.subPrivacy p a {
    color: white;
    font-size: 15px;
    font-weight: 600;
    padding-right: 1rem;
    text-decoration: underline;
    background-color: transparent;
}
.subPrivacy p a:hover {
    color: rgb(24, 160, 108);
}