@media only screen and (max-width: 876px) {
    .container-nav .navbar {
        display: none;
    }

    .work-space {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: transparent;
    }
    .work-space img {
        flex-wrap: wrap;
        padding-top: 2rem;
        padding-right: 5rem;
        background-color: transparent;
    }



    .box-jurnal {
        flex-wrap: wrap;flex-direction: column;
    }


    .view-talk iframe {
        width: 100%;
    }
}




@media only screen and (max-width: 414px) {
    .container-nav .navbar {
        display: none;
    }

    .container-beranda {
        width: 100%;
        min-width: 200px;
        margin: auto;
    }
    .hero-beranda {
        width: 90%;
        padding-top: 60px;
        padding-bottom: 60px;
        margin: auto;
    }
    .hero-beranda h1 {
        font-size: 36px;
    }

    .hero-beranda button {
        color: black;
        font-size: 17px;
        border-radius: 24px;
        box-shadow: 5px 10px;
        border-radius: 4px;
        padding: 15px 20px;
    }
    .hero-beranda button:hover {
        color: white;
    }
    




    /* Feature Work  */
    .bg-feature-beranda {
        /* background: radial-gradient(circle at top left, #beeef059, #a2db1b49);  */
        padding-top: 30px;
        background-color: rgba(255, 248, 242, 0.445);
        padding-bottom: 30px;
    }
    .feature-beranda {
        justify-content: center;
        width: 90%;
        margin: auto;
        background-color: transparent;
    }
    .feature-beranda h2 {
        font-size: 36px;
        font-weight: 800;
        background-color: transparent;
    }
    
    .work-space {
        width: 90%;
        margin: auto;
        display: flex;
        justify-content: center;
        background-color: transparent;
    }
    .work-space img {
        max-width: 100%;
        height: 50px;
        padding: 10px;
        flex-wrap: wrap;
        background-color: transparent;
    }


/* Jurnal */
.box-jurnal {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}


/* CONTACT  */
.box-contact{
    height: 50%;
    flex-direction: column;
    background-color: transparent;
}
.lets-talk {
    background-color: transparent;
    padding-bottom: 30px;
}
.lets-talk h1 {
    font-size: 24px;
    font-weight: 800;
    color: rgb(190, 42, 42);
    background-color: transparent;
}

.lets-talk p {
    font-size: 17px;
    color: rgb(190, 42, 42);
    background-color: transparent;
}

.view-talk iframe {
    width: 100%;
}


    /* FOOTER  */
    .buttom-footer-sidebar {
        height: 100%;
    }

    .logo-footer {
        flex-direction: column;
    }

    .sidebar-footer {
        flex-direction: column;
        padding-top: 2rem;
    }

    /* Mengatur List Menu  */
    .twofooter {
        padding-top: 1rem;
    }
    .twofooter li {
        padding-top: 4px;
    }

    .threefooter {
        padding-top: 1rem;
    }
    .threefooter li { 
        padding-top: 4px;
    }

    /* Buttom footer */
    .buttom-footer {
        width: 100%;
        margin: auto;
        padding-top: 5rem;
        padding-bottom: 40px;
        background-color: rgb(42,43,47);
        align-items: center;
    }
    .buttom-footer-sidebar {
        padding-top: 1rem;
    }
    .subPrivacy p a {
        display: none;
    }

    .subIcon a {
        font-size: 20px;
    }
}